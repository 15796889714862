import React, { useState } from "react";
import {
  Button
} from 'reactstrap';
import { generateFile } from "./generate_file.js";
import I18n from 'i18n-js';

const DocumentPreview = (props) => {
  const [enableDraw, setEnableDraw] = useState(false);
  const [file, setFile] = useState('');
  const [disableButtonPreview, setDisableButtonPreview] = useState(false);

  const drawDocument = () => {
    if(enableDraw){
      return(
        <iframe width="100%" height="750" src={ URL.createObjectURL(file) } frameBorder="0"></iframe>
      )
    }
  }

  const handleClickPreview = (e) => {
    e.preventDefault();
    setDisableButtonPreview(true);
    const form = document.getElementsByTagName("form");
    const tagForm = document.getElementById(form[0].id)
    const formData = new FormData(tagForm);

    generateFile(formData, props?.template.id, props?.document.id, tagForm.id, (response)=>{
      if (response != undefined || response != null) {

        if (response.status === 201) {
          const content = document.getElementById("content-template-request")
          content.style.display = "none";
          var blob = new Blob([response.data], {type: 'application/pdf'});
          setFile(blob);
          setEnableDraw(true)
        } else {
          failure("Invalid JSON: " + response);
        }
      }
      setDisableButtonPreview(false);
    })
  }

  const handleClickEdit = (e) => {
    e.preventDefault();
    const content = document.getElementById("content-template-request")
    content.style.display = "block";
    setEnableDraw(false)
  }

  const drawButtonEdit = () => {
    if(enableDraw){
      return(
        <Button
          onClick={ (e) => handleClickEdit(e) }
          type="type"
          className="mr-3"
        >
          Editar
        </Button>
      )
    }
  }

  const drawButtonPreview = () => {
    if(!enableDraw){
      return(
        <Button
          onClick={ (e) => handleClickPreview(e) }
          type="submit"
          disabled={disableButtonPreview}
          className="btn btn-info mr-3"
        >
        { I18n.t(
          "documents.fill.preview"
        ) }
        </Button>
      )
    }
  }

  const drawButtonSave = () => {
    return(
      <Button
        id="submit-template-request"
        className="btn btn-success btn-icon btn-save"
        type="submit"
        data-title={ I18n.t(
          "documents.fill.confirm.title"
        ) }
        data-confirm={ I18n.t(
          "documents.fill.confirm.message"
        ) }
      >
        { I18n.t(
          "documents.fill.send"
        ) }
      </Button>
    )
  }

  return (
    <div>
      <div className="mb-3">
        { drawDocument() }
      </div>
      <p className="text-primary small font-weight-bold">
        <i className="fas fa-exclamation-circle mr-2"></i>
        { I18n.t("documents.fill_help.confirm_info") }
      </p>
      { drawButtonEdit() }
      { drawButtonPreview() }
      { drawButtonSave() }
    </div>
  );
};

export default DocumentPreview;
