// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

import "../styles/application.scss";

import "bootstrap";

import I18n from "i18n-js/index.js.erb";
import "sweetalert";
// import "@fortawesome/fontawesome-free/js/all";

require("admin-lte/dist/js/adminlte");
require("packs/components");

require("inputmask");

const _ = require("lodash");

window.Routes = require("js-routes/index.js.erb");

Rails.start();
Turbolinks.start();
ActiveStorage.start();

const images = require.context("../images", true);

window.bootstrap = require("bootstrap");

import swal from 'sweetalert2'

window.swalWithBootstrap = swal.mixin({
  customClass: {
    confirmButton: 'btn btn-success ml-3',
    cancelButton: 'btn btn-secondary'
  },
  showCancelButton: true,
  cancelButtonText: "<i class='fa fa-thumbs-down'></i> Cancelar",
  confirmButtonText: "<i class='fa fa-thumbs-up'></i> Confirmar",
  reverseButtons: true,
  buttonsStyling: false
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip({ html: true });
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
});
