import React, { useEffect, useState } from 'react';
import SignerForm from './form';
import {
  Button
} from 'reactstrap';

const SignerList = (props) => {
  const formName = props?.formName || 'signers'
  const [signers, setSigners] = useState(props?.signers || []);

  useEffect(() => {
    _.each(signers, function(signer){
      signer['_destroy'] = false
    })
  }, [])
  
  const handleAddSigner = (e) => {
    let signer = {
      id: '',
      email: '',
      label: 'Firmante',
      is_person_signer: false,
      _destroy: false
    }

    setSigners([...signers, signer]);
  };

  const handleSigner = (signer, index) => {
    let _signers = [ ... signers ]
    _signers[index] = signer;

    if(signer.is_person_signer){
      _.each(_signers, function(__signer, __index){
        if(__index != index){
          __signer.is_person_signer = false
        }
      })
    }

    setSigners(_signers)
  }

  const drawSigners = () => {
    if(signers.length > 0){
      return (
        <div className="row"> 
          <div className="col-12"> 
            { _.map(signers, (signer, index) => (
              <SignerForm
                key={ index }
                index={ index }
                signer={ signer }
                formName={ `${ formName }[signers_attributes][${ index }]` }
                handleSigner={ handleSigner }
              />
            ))}
          </div> 
        </div>
      );
    } else {
      return (
        <div className="card"> 
          <div className="card-body">
            <div className="alert alert-warning text-center">
              Aún no tienes firmantes
            </div>
          </div>
        </div>
      );
      
    }
  }

  return(
    <div className="col-12">
      <div className="text-right">
        <Button
          size="sm"
          color="info"
          outline
          className="mb-2 "
          onClick={ (e) => handleAddSigner(e) }
        >
          <i className="fas fa-plus" /> Agregar Firmante
        </Button>
      </div>
      { drawSigners() }
    </div>
  )
}

export default SignerList;
