import axios from "axios";
import {csrf} from '../../packs/token'
import swal from "sweetalert2";

export const generateFile = (data, id, documentId, idForm, callback) => {

  let config = {
    method: "POST",
    url: Routes.preview_pdf_documents_path(id)
  }

  if (idForm != "new_document"){
    config = {
      method: "PATCH",
      url: Routes.preview_pdf_document_path(id, documentId)
    }
  }

  let promise = axios({
    ...config,
    data: data,
    headers: {
      "X-CSRF-TOKEN": csrf,
      enctype: "multipart/form-data"
    },
    responseType: 'blob'
  });

  promise
    .then((response) => {
      if (response){
        callback(response);
      }
    })
    .catch((err) => {
      swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Error al previsualizar el archivo',
        })
      callback(err.response);
    });
};
