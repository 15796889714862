import React, { Fragment, useEffect, useState } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import I18n from "i18n-js";
import { inputId, inputName } from "../helper/form";
import SignerList from "../signers/list";
import Select from "react-select";

const TemplateForm = props => {
  const formName = props?.formName || "template";
  const documentTypes = _.sortBy(props?.documentTypes || [], "name");
  const currentUser = props?.currentUser || {};
  const varsDataTypes = props?.varsDataTypes || [];
  const signIntegratorUsers = props?.signIntegratorUsers || [];

  const [template, setTemplate] = useState(props?.template || {});
  const [name, setName] = useState(props?.template?.name || "");
  const [isPublic, setIsPublic] = useState(props?.template?.public || false);
  const [isControlDocIntegration, setIsControlDocIntegration] = useState(
    props?.template?.enable_controldoc_integration || false
  );
  const [content, setContent] = useState(props?.template?.content || "");
  const [isFork, setIsFork] = useState(props?.template?.prent_id == null);
  const [parentForkId, setParentForkId] = useState(
    props?.template?.parent_id || ""
  );
  const [variables, setVariables] = useState([]);
  const [documentTypeId, setDocumentTypeId] = useState(
    props?.template?.cd_document_type_id || ""
  );
  const [signIntegratorUserId, setSignIntegratorUserId] =  useState(
    props?.template?.sign_integrator_user_id || ''
  )
  const [showPageNumber, setShowPageNumber] = useState(props?.template?.show_page_number || false)

  const [templateLayoutId, setTemplateLayoutId] = useState(props?.template?.template_layout_id);

  useEffect(() => {
    if(!signIntegratorUserId && currentUserIsSignIntegratorUser){
      setSignIntegratorUserId(currentUser.id)
    }
  }, [])

  useEffect(() => {
    tinyMCE.init({
      selector: "#template_content",
      language: "es_ES",
      height: 500,
      entity_encoding: "raw",
      plugins:
        "print preview advlist lists paste table charmap fullscreen textcolor link",
      toolbar:
        "varMenuButton | bold italic underline forecolor backcolor | undo redo | link | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | print | charmap | fullscreen | image",
      setup: function (editor) {
        editor.ui.registry.addMenuButton("varMenuButton", {
          text: "Crear Variables",
          fetch: function (callback) {
            var items = _.map(varsDataTypes, function (_var) {
              return {
                type: "menuitem",
                text: _var.label,
                onAction: function () {
                  let content = editor.selection.getContent();
                  const nonAlphanumericRegex = /([^a-zA-Z\d:])/g;
                  const whitespaceRegex = /([\s])/g;

                  content = _.deburr(content)
                    .replaceAll(nonAlphanumericRegex, "_")
                    .replaceAll(whitespaceRegex, "_")
                    .replaceAll(".", "")
                    .toLowerCase() || _.uniqueId(`${ _var.name }_`);
                  editor.insertContent(`[[${content}]/${_var.name}/]`);
                },
              };
            });
            callback(items);
          },
        });
      },
      init_instance_callback: function (editor) {
        editor.on("change", function (e) {
          let text = e.target.getContent();
          let vars = getAllVariableFromText(text);
          setContent(text);
          setVariables(vars || []);
        });
      },
    });
  })

  useEffect(() => {
    let _vars = getAllVariableFromText(template?.content || "");
    setVariables(_vars || []);

    $('[data-toggle="tooltip"]').tooltip();
  }, [])

  const getAllVariableFromText = data => {
    // let re = /(\[\[\w+\]\])+/g;
    let re = /(\[\[\w+\]\/\w+\/\])+/g;

    let _vars = data.match(re);

    let vars = _.map(_vars, function (_var) {
      var __reType = /\/\w+\/+/g;
      var __type = _var.match(__reType)[0];

      return {
        var: _var,
        name: _var.replace(__type, ""),
        type: __type?.replaceAll("/", ""),
      };
    });

    return vars;
  }

  const drawVariables = () => {
    if (variables.length > 0) {
      return (
        <div className="card" key={_.map("card-variable-")}>
          <div className="card-body">
            <h6 className="card-subtitle mb-2 text-muted">
              Variables utilizadas
            </h6>
            {_.map(variables, function (_var, index) {
              return (
                <span
                  className="badge badge-secondary mr-2"
                  key={`${_.uniqueId("var")}`}
                >
                  <input
                    type="hidden"
                    name={`${inputName(formName, "vars")}[${_var.name}][name]`}
                    value={_var.name}
                  />

                  <input
                    type="hidden"
                    name={`${inputName(formName, "vars")}[${_var.name}][type]`}
                    value={_var.type}
                  />

                  <input
                    type="hidden"
                    name={`${inputName(formName, "vars")}[${_var.name}][var]`}
                    value={_var.var}
                  />
                  {`${_var.name} - ${_var.type}`}
                </span>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <div className="card">
          <div className="card-body">
            <div className="alert alert-warning text-center">
              Aún no tienes variables
            </div>

            {drawErrorsVars()}
          </div>
        </div>
      );
    }
  }

  const drawErrorsVars = () => {
    if (_.has(template.errors, "vars")) {
      return (
        <ul className="errors_list">
          {_.map(template.errors.vars, function (_error) {
            return <li key={_.uniqueId("error_vars_")}>{_error}</li>;
          })}
        </ul>
      );
    }
  }

  const drawErrors = key => {
    if (_.has(template.errors, key)) {
      return _.map(template.errors[key], function (_error) {
        return <FormFeedback key={_.uniqueId("error_")}>{_error}</FormFeedback>;
      });
    }
  }

  const drawControlDocSignSwitch = () => {
    if (true) {
      return (
        <div className="col-6">
          <FormGroup>
            <div className="custom-control custom-switch">
              <Input
                type="hidden"
                name={inputName(formName, "enable_controldoc_integration")}
                value="0"
              />
              <Input
                className="custom-control-input"
                id={inputId(formName, "enable_controldoc_integration")}
                type="checkbox"
                onChange={e => setIsControlDocIntegration(e?.target?.checked)}
                value="1"
                checked={isControlDocIntegration || false}
                name={inputName(formName, "enable_controldoc_integration")}
                invalid={_.has(template.errors, "signers")}
              />
              <Label
                className="custom-control-label"
                for={inputId(formName, "enable_controldoc_integration")}
              >
                {I18n.t(
                  "activerecord.attributes.template.enable_controldoc_integration"
                )}
              </Label>
              <button
                type="button"
                className="btn btn-link p-0 ml-2"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={I18n.t(
                  "templates.form.enable_controldoc_integration.help"
                )}
              >
                <i className="fas fa-info-circle text-info fa-lg"></i>
              </button>

              { drawButtonAdvancedConfControldocSign() }

              {drawErrors("signers")}
            </div>
          </FormGroup>
        </div>
      );
    }
  }

  const drawButtonAdvancedConfControldocSign = () => {
    if(isControlDocIntegration){
      return(
        <button
          type='button'
          className='btn btn-link p-0 text-success'
          data-bs-toggle="collapse"
          href="#collapseAdvancesConfControldocSign"
          role="button"
          aria-expanded="false"
          aria-controls="collapseAdvancesConfControldocSign"
        >
          <i className="fas fa-cog fa-lg"></i>
        </button>
      )
    }
  }

  const drawDocumentTypeOptionsSelect = () => {
    return _.map(documentTypes, function (dt) {
      return (
        <option key={dt.id} value={dt.id}>
          {dt.name}
        </option>
      )
    })
  }

  const currentUserIsSignIntegratorUser = () => {
    return currentUser.id == signIntegratorUserId && currentUser.controldoc_sign_integration_enabled && _.find(signIntegratorUsers, { email: currentUser.email });
  }

  const drawAdvancesConfControldocSign = () => {
    if(isControlDocIntegration){
      return(
        <div className="col-12">
          <div className={ `collapse ${ currentUserIsSignIntegratorUser() ? '' : 'show' }`} id="collapseAdvancesConfControldocSign">
            <div className="card card-body">
              { drawSignIntegratorUserSelect() }
            </div>
          </div>
        </div>
      )
    }
  }

  const drawSignIntegratorUserSelect = () => {
    if (isControlDocIntegration){
      return(
        <div className="col-12">
          <FormGroup>
            <Label
              for={ inputId(formName, 'sign_integrator_user_id') }
              className='required'
            >
              { I18n.t("activerecord.attributes.template.sign_integrator_user_id") }
            </Label>

            <Input
              type='select'
              value={ signIntegratorUserId }
              name={ inputName(formName, 'sign_integrator_user_id') }
              placeholder="-- Seleccionar un usuario integrador para ControldocSign --"
              onChange={ e => setSignIntegratorUserId(e?.target?.value) }
              invalid={ _.has(template.errors, "sign_integrator_user") }
            >
              <option></option>
              { drawSignIntegratorUserOptionsSelect() }
            </Input>
            <small className='text-muted'>
              Es el usuario que se usará como creador del documento al momento de enviarlo a firma
            </small>

            { drawErrors("sign_integrator_user") }
          </FormGroup>
        </div>
      )
    }
  }

  const drawSignIntegratorUserOptionsSelect = () => {
    return _.map(signIntegratorUsers, user => {
      return(
        <option
          value={ user.id }
          key={ `user-sign-integrator-option-${ user.id }`}
        >
          { user.email }
        </option>
      )
    })
  }

  // const drawDocumentTypesSelect = () => {
  //   if (isControlDocIntegration) {
  //     return (
  //       <div className="col-12">
  //         <FormGroup>
  //           <Label
  //             for={inputId(formName, "cd_document_type_id")}
  //             className='required'
  //           >
  //             {I18n.t("activerecord.attributes.template.cd_document_type_id")}
  //           </Label>
  //           <Input
  //             type="select"
  //             value={documentTypeId || ""}
  //             id={inputId(formName, "cd_document_type_id")}
  //             name={inputName(formName, "cd_document_type_id")}
  //             placeholder="-- Seleccionar tipo de documento --"
  //             onChange={e => setDocumentTypeId(e?.target?.value)}
  //             invalid={_.has(template.errors, "cd_document_type_id")}
  //           >
  //             {drawDocumentTypeOptionsSelect()}
  //           </Input>
  //           {drawErrors("cd_document_type_id")}
  //         </FormGroup>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <Input
  //         name={inputName(formName, "cd_document_type_id")}
  //         value=""
  //         type="hidden"
  //       />
  //     );
  //   }
  // }

  const drawSignerList = () => {
    if (isControlDocIntegration) {
      return (
        <SignerList signers={template?.signers || []} formName={formName} />
      );
    }
  }

  const getTemplateLayoutSelectedOption = () => {
    let selected = _.find(props?.templateLayouts, { id: templateLayoutId });

    if(selected){
      return { value: selected.id, label: selected.name }
    } else {
      return null;
    }
  }

  const drawSelectTemplateLayoutOptions = () => {
    return _.map(props?.templateLayouts, templateLayout => ({ label: templateLayout.name, value: templateLayout.id }))
  }

  const drawTemplateLayoutsInputSelect = () => {
    return(
      <FormGroup>
        <Label
          for={ inputId(formName, "template_layout_id") }
        >
          { I18n.t('activerecord.attributes.template.template_layout_id') }
        </Label>

        <Select
          styles={{
            menu: provided => ({ ...provided, zIndex: 9999 })
          }}
          id={ inputId(formName, 'template_layout_id') }
          name={ inputName(formName, 'template_layout_id') }
          onChange={ e => setTemplateLayoutId( e?.value )}
          value={ getTemplateLayoutSelectedOption() }
          options={ drawSelectTemplateLayoutOptions() }
          isClearable={ true }
          placeholder={ `-- Selecciona un ${ I18n.t('activerecord.attributes.template.template_layout_id') } --` }
        >
        </Select>

      </FormGroup>
    )
  }

  return (
    <div className="row">
      <div className="col-12">
        <FormGroup>
          <Label
            for={inputId(formName, "name")}
            className='required'
          >
            {I18n.t("activerecord.attributes.template.name")}
          </Label>
          <Input
            type="text"
            className="form-control"
            id={inputId(formName, "name")}
            name={inputName(formName, "name")}
            value={name || ""}
            onChange={e => setName(e?.target?.value)}
            invalid={_.has(template.errors, "name")}
          />
          {drawErrors("name")}
        </FormGroup>
      </div>

      { drawTemplateLayoutsInputSelect() }

      <div className="col-6">
        <FormGroup>
          <div className="custom-control custom-switch">
            <Input
              type="hidden"
              name={inputName(formName, "public")}
              value="0"
            />
            {isFork && (
              <Fragment>
                <Input
                  type="hidden"
                  name={inputName(formName, "parent_id")}
                  value={parentForkId}
                />
              </Fragment>
            )}
            <Input
              className="custom-control-input"
              id={inputId(formName, "public")}
              type="checkbox"
              onChange={e => setIsPublic(e?.target?.checked)}
              value="1"
              checked={isPublic || false}
              name={inputName(formName, "public")}
            />
            <Label
              className="custom-control-label"
              for={inputId(formName, "public")}
            >
              {I18n.t("activerecord.attributes.template.public")}
            </Label>
            <span className="ml-2">
              <i
                className="fas fa-info-circle text-info fa-lg"
                data-toggle="tooltip"
                data-placement="top"
                title={I18n.t("templates.form.public.help")}
              ></i>
            </span>
          </div>
        </FormGroup>
      </div>

      { drawControlDocSignSwitch() }

      { drawAdvancesConfControldocSign() }

      {/*{drawDocumentTypesSelect()}*/}

      {drawSignerList()}

      <div className="col-12">
        <FormGroup>
          <Label
            for={ inputId(formName, "content") }
            className='required'
          >
            {I18n.t("activerecord.attributes.template.content")}
          </Label>

          {drawVariables()}
        </FormGroup>
        <div className="form-group">
          <Input
            type="textarea"
            className="form-control"
            id={inputId(formName, "content")}
            name={inputName(formName, "content")}
            value={content || ""}
            onChange={e => setContent(e?.target?.content)}
          />

          {drawErrors("content")}
        </div>
      </div>

      {/* <div className="col-12">
        <FormGroup>
          <div className="custom-control custom-switch">
            <Input
              type="hidden"
              name={inputName(formName, "show_page_number")}
              value="0"
            />
            <Input
              className="custom-control-input"
              id={ inputId(formName, "show_page_number") }
              type="checkbox"
              onChange={e => setShowPageNumber(e?.target?.checked)}
              value="1"
              checked={ showPageNumber || false }
              name={inputName(formName, "show_page_number")}
            />
            <Label
              className="custom-control-label"
              for={inputId(formName, "show_page_number")}
            >
              {I18n.t("activerecord.attributes.template.show_page_number")}
            </Label>
            <span className="ml-2">
              <i
                className="fas fa-info-circle text-info fa-lg"
                data-toggle="tooltip"
                data-placement="top"
                title={I18n.t("templates.form.show_page_number.help")}
              ></i>
            </span>
          </div>

        </FormGroup>
      </div> */}
    </div>
  );
};

export default TemplateForm;
