import React, { useEffect } from 'react';
import * as XLSX from 'xlsx';
import { Input, Label } from 'reactstrap';
import swal from "sweetalert2";

const DocumentFile = ({ handleValues }) => {

  const handleFile = (_target) => {
    let file = _target;
    let page = [];
    if(file != "" && file.type =="file"){
      let isXlsx = file.value.split("\\").pop().includes(".xlsx");
      if (isXlsx){
        let reader = new FileReader();
        reader.readAsArrayBuffer(file.files[0]);
        reader.onloadend = (e) => {
          let data = new Uint8Array(e.target.result);
          let workbook = XLSX.read(data,{type: "array"});
          workbook.SheetNames.forEach(function(sheetName){
            let XL_row_objet = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            XL_row_objet.forEach((email)=>{
              page.push({value: email.emails, label: email.emails, name: email.names, __isNew__:true})
            })
          })
          handleValues(page,"create-option");
        }
      }else{
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'El documento debe ser formato excel',
        })
      }
    }
  }


  return(
    <div className="row">
      <div className="col-12 mb-4">
        <Label>Archivo Excel</Label>

        <Input
          type="file"
          name="file"
          id="file"
          placeholder="Archivo de Excel"
          onChange={ (e) => handleFile(e.target) }
        />
        <small>
          Usar esta plantilla base &nbsp;
          <a
            href={ Routes.download_example_documents_path() }
            target='_blank'
          >descargar</a>
        </small>
      </div>
    </div>
  )
}
export default DocumentFile;
