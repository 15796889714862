import axios from "axios";
import {csrf} from '../../packs/token'
import swal from "sweetalert2";

export const saveImage = (data, id) => {

  var promise = axios({
    method: "PATCH",
    data: data,
    url: `/orgs/${id}`,
    headers: {
      "X-CSRF-TOKEN": csrf,
      enctype: "multipart/form-data"
    },
  });

  promise
    .then((response) => {
      if (response){
        swal.fire({
          icon: 'success',
          title: 'Guardado',
          text: 'Su configuración se guardó correctamente',
        })
      }
    })
    .catch((err) => {
      swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Algo salio mal, verifique el archivo de imagen',
        })
    });
};
