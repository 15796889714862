import React from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import I18n from "i18n-js";

const TemplateLayoutPreview = props => {

  let { showPreviewModal, setShowPreviewModal, previewPdfUrl } = props;

  const modalProps = { size: 'xl' }

  const toggle = () => {
    setShowPreviewModal(false)
  }

  return(
    <div className="row">
      <div className="col-12">
        <Modal isOpen={ showPreviewModal } toggle={ toggle } { ... modalProps }>
          <ModalHeader toggle={ toggle }>
            Previsualización
          </ModalHeader>
          <ModalBody>
            <iframe
              width="100%"
              height="750"
              src={ previewPdfUrl || '' }
              frameBorder="0"
              type="application/pdf"
            ></iframe>
          </ModalBody>
          <ModalFooter>
            <Button color="default" onClick={ toggle }>
              { I18n.t('actions.close') }
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  )
}

export default TemplateLayoutPreview;
