import WebpackerReact from "webpacker-react";
import Turbolinks from "turbolinks";

import TemplateForm from "../components/template/form";
import Document from "../components/template/document";
import BrandConfigurationForm from "../components/customer/form";
import DocumentPreview from "../components/document/preview";
import TemplateLayoutForm from "../components/template_layout/form";

Turbolinks.start();

WebpackerReact.setup({
  TemplateForm,
  Document,
  BrandConfigurationForm,
  DocumentPreview,
  TemplateLayoutForm,
});
