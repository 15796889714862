import axios from "axios";
import { csrf } from "../../packs/token";

export const create = ( requestParams, formData, callback ) => {
  let promise = axios({
    method: 'POST',
    url: Routes.template_layouts_path({ "format": "json" }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": csrf,
    }
  });

  promise
    .then(response => {
      callback(response)
    })
    .catch(error => {
      callback(error.response)
    })
};

export const update = (requestParams, formData, callback) => {
  let promise = axios({
    method: 'PATCH',
    url: Routes.template_layout_path({ ...{ ... requestParams }, "format": "json", _options: true }),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": csrf,
    },
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};

export const preview = (requestParams, formData, callback) => {
  let promise = axios({
    method: 'POST',
    url: Routes.preview_template_layouts_path(),
    data: formData,
    headers: {
      "X-CSRF-TOKEN": csrf,
    },
    responseType: 'blob'
  });

  promise
    .then(response => {
      callback(response);
    })
    .catch(error => {
      callback(error.response);
    });
};
