import swal from "sweetalert2";
import Rails from "@rails/ujs";

Rails.confirm = function (message, element, oli) {
  const title = element.getAttribute("data-title")

  swalWithBootstrap
    .fire({
      html: message,
      title: title,
      showCancelButton: true,
      cancelButtonText: "<i class='fa fa-thumbs-down'></i> Cancelar",
      confirmButtonText: "<i class='fa fa-thumbs-up'></i> Confirmar",
      reverseButtons: true
    })
    .then((result) => {
      if (result.value) {
        element.removeAttribute("data-confirm");
        element.click();
      }
    });
};
