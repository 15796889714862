import React, { useState } from 'react'
import { CirclePicker } from 'react-color';

const SelectColor = (props) =>{
  const [ color, setColor] = useState(props.color);

  const handleChange = color => {
    setColor({ color: color.hex });
    props.handlerColor(color.hex);
  };

  return(
    <div className="d-flex justify-content-center align-items-center mt-3">
      <CirclePicker color={ color } onChange={ handleChange } />
    </div>
  )
}

export default SelectColor;
