import React, { useEffect, useState } from 'react';
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Button
} from 'reactstrap';
import { inputId, inputName } from '../helper/form';
import I18n from 'i18n-js';

const SignerForm = (props) => {
  const formName = props?.formName || 'signer';

  const [signer, setSigner] = useState(props?.signer || {})
  
  const handleDestroySigner = () => {
    setSigner({...signer, _destroy: true})
  }

  const drawButtonDestroySigner = () => {
    return(
      <div className="text-right">
        <Button
          size="sm"
          color="danger"
          outline
          className="mb-2"
          onClick={ (e) => handleDestroySigner() }
        >
          <i className="fas fa-trash" /> Eliminar
        </Button>
      </div>
    );
  }

  const drawFormInput = attribute => {
    return(
      <Input
        type="hidden"
        id={ inputId(formName, attribute) }
        name={ inputName(formName, attribute) }
        defaultValue={ signer[attribute] || "" }
      />
    );
  }

  const handleOnChangePersonSigner = (e, index) => {
    const { checked } = e.target;
    let _signer = { ... signer }
    _signer.is_person_signer = checked;

    setSigner(_signer);

    if(typeof props?.handleSigner === 'function'){
      props?.handleSigner(_signer, index)
    }
  }

  const drawSwitchPersonSigner = () => {
    return(
      <FormGroup>
        <div className="custom-control custom-switch">
          <Input
            type="hidden"
            name={ inputName(formName, 'is_person_signer') }
          />
          <Input
            className="custom-control-input"
            id={ inputId(formName, "is_person_signer") }
            type="checkbox"
            onChange={ (e) => handleOnChangePersonSigner(e, props?.index) }
            checked={ signer?.is_person_signer || false }
            name={ inputName(formName, "is_person_signer") }
          />
          <Label
            className="custom-control-label"
            htmlFor={ inputId(formName, "is_person_signer") }
          >
            { I18n.t("activerecord.attributes.signer.is_person_signer") }
          </Label>
        </div>
      </FormGroup>
    );
  }

  const drawErrors = (key) => {
    if (_.has(signer.errors, key)) {
      return _.map(signer.errors[key], function (_error) {
        return <FormFeedback key={ _.uniqueId("error_") }>{_error}</FormFeedback>;
      });
    }
  };

  const drawEmail = () => {
    if(signer?.is_person_signer){
      return(
        <div>
          <Label> Correo electrónico de quien completa el documento </Label>
        </div>
      );
    } else {
      return(
        <div>
          <Input
            type="email"
            id={ inputId(formName, "email") }
            name={ inputName(formName, "email") }
            defaultValue={ signer?.email || "" }
            invalid={ _.has(signer?.errors, "email") }
          />
          { drawErrors('email') }
        </div>
      );
    }
  }

  const drawForm = () => {
    if(signer?._destroy === false){
      return(
        <div className="card">
          <div className="card-body">
            { drawSwitchPersonSigner() }
            <FormGroup>
              <Label for={ inputId(formName, "email") }>
                { I18n.t("activerecord.attributes.signer.email") }
              </Label>
              { drawEmail() }
            </FormGroup>
            <FormGroup>
              <Label for={ inputId(formName, "label") }>
                { I18n.t("activerecord.attributes.signer.label") }
              </Label>
              <Input
                type="text"
                id={ inputId(formName, "label") }
                name={ inputName(formName, "label") }
                defaultValue={ signer?.label || "" }
                invalid={ _.has(signer?.errors, "label") }
              />
              { drawErrors('label') }
            </FormGroup>
            { drawButtonDestroySigner() }
            { drawFormInput('_destroy') }
            { drawFormInput('id') }
          </div>
        </div>
      );
    } else{
      return(
        <div>
          { drawFormInput('_destroy') }
          { drawFormInput('id') }
        </div>
      );
    }
  }
  
  return(
    <div className="row">
      <div className="col-12">
        { drawForm() }
      </div>
    </div>
  );
}

export default SignerForm;
