import React, { useEffect, useState, useRef } from 'react';
import DocumentFile from './file';
import { inputId, inputName, validateEmail } from '../helper/form';
import CreatableSelect from 'react-select/creatable';
import { FormGroup, Input, Label} from 'reactstrap';

const Document = (props) => {

  const formName = props?.formName || 'template'
  const [emails, setEmails] = useState([])
  const [emailsErrors, setEmailsErrors] = useState([])

  let refTableSelect = useRef();

  const handleValues =(_emails)=>{
    _emails = refTableSelect.current.state.selectValue.concat(_emails);
    _emails.forEach((_email,index)=>{
      if(!validateEmail(_email.value)){
        emailsErrors.push(_email.value)
      }
      if(_emails.length-1 == index){
        setEmailsErrors(emailsErrors)
      }
    })
    const ids = _emails.map(o => o.value)
    _emails = _emails.filter(({value}, index) => !ids.includes(value, index + 1) && validateEmail(value))
    refTableSelect.current.setValue(_emails)
  }

  const handleNames = (e) =>{
    emails.map((value, index)=>{
      if(index == e.id){
        value.name = e.value
      }
    })
  }

  const drawEmails = () => {
    return(
      <div className="row">
        { _.map(emails, function(email, index){
          return(
            <div className="col-12" key={ _.uniqueId('email_card_') }>
              <input type="hidden"
                name={ `${formName}[documents_attributes][][email]` }
                value={ email.value }
              />
              <div className="card">
                <div className="card-body d-flex">
                  <div className='col-2'>
                    <Label>Email:</Label>
                  </div>
                  <div className='col-4'>
                    { email.value }
                  </div>
                  <div className='col-2'>
                    <Label>Nombre:</Label>
                  </div>
                  <div className='col-4'>
                    <Input type="text"
                      id={index}
                      className='form-control-border'
                      onChange={e=>handleNames(e.target)}
                      name={ `${formName}[documents_attributes][][name]` }
                      defaultValue={ email.name }
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  const drawEmailsErrors = () => {
    return(
      <div className="row">
        { _.map(emailsErrors, function(email){
          return(
            <div className="col-12" key={ _.uniqueId('email_card_') }>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-danger">{ `${email} no es un Email`}</h5>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  const filterOptions = (candidate, input ) => {
    return validateEmail(candidate.value)
  };

  return(
    <div className="row">
      <div className="col-12">
        <FormGroup>
          <Label>Correos Electrónicos</Label>
          <CreatableSelect
            defaultValue={emails}
            isMulti
            onChange={setEmails}
            hideSelectedOptions={false}
            filterOption={filterOptions}
            ref={refTableSelect}
            noOptionsMessage={ () => null }
          />
        </FormGroup>
      </div>
      <div className="col-12">
        { drawEmails() }
      </div>
      <div className="col-12">
        { drawEmailsErrors() }
      </div>
      <div className="col-12">
        <DocumentFile
        handleValues={ handleValues }
        />
      </div>
    </div>
  )
}


export default Document;
