import React, { useState, useRef, useEffect } from "react";

const TemplateLayoutEditable = props => {
  const { editorRef, context } = props;

  const configEditable = {
    selector: `.template-layout-${ context }`,
    menubar: false,
    inline: true,
    max_height: 150,
    relative_urls : false,
    remove_script_host : false,
    entity_encoding : "raw",
    placeholder: `Configura tu ${ context == 'header' ? 'Cabecera' : 'Pie de Página' } del documento`,
    plugins: [
      'lists',
      'autolink'
    ],
    toolbar: "bold italic underline forecolor backcolor | undo redo | link  | alignleft aligncenter alignright alignjustify | numberPage",
    valid_elements: 'strong,em,span[style],a[href],ul,ol,li,h2[style],br,p[style]',
    setup: editor => {
      editor.on("init", e => { editorRef.current = editor })
      editor.ui.registry.addButton('numberPage', {
        text: 'Número de página',
        onAction: _ => {
          editor.insertContent("[[numberPage]]")
        }
      })
    }
  };

  useEffect(() => {
    tinyMCE.init(configEditable);
  }, [])

  return (
    <>
      <div
        className={ `template-layout-${ context }` }
        dangerouslySetInnerHTML={{__html: (props?.templateLayout || {})[context]}}
        style={{ border: "1px dashed", maxHeight: '100px' }}
      >
      </div>
    </>
  )
}

export default TemplateLayoutEditable;
