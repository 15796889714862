import React, { useState } from "react";
import SelectColor from './select_color'
import DefaultLogo from '../../images/logo.png'
import { saveImage } from "./save_image.js";
import Resizer from 'react-image-file-resizer';

const BrandConfigurationForm = ({customer}) =>{

  const [brandColor, setBrandColor] = useState(customer?.brand_color || "#5a3ee0")
  const [brandLogo, setBrandLogo] = useState("")
  const [previewLogo, setPreviewLogo] = useState(customer?.brand_logo_url || DefaultLogo)
  const [typeLogo, setTypeLogo] = useState("")
  const [nameLogo, setNameLogo] = useState("")

  const handlerColor =( color )=>{
    setBrandColor( color )
  }

  const handleImageChange = (event) => {
    var file = event.files[0];
    Resizer.imageFileResizer(
      file,
      250,
      250,
      "JPEG",
      100,
      0,
      (uri) => {
        setBrandLogo(uri.split(",")[1])
        setPreviewLogo(uri)
      },
      "base64"
    )

    let reader = new FileReader();

    reader.onloadend = () => {
      setTypeLogo(file.type)
      setNameLogo(file  .name)
    }
    reader.readAsDataURL(file)
  }

  const handlerSubmit = (e)=> {
    let formData = new FormData();

    formData.append( "brand_color", brandColor)
    formData.append( "brand_logo", brandLogo )
    formData.append( "type_logo", typeLogo )
    formData.append( "name_logo", nameLogo )

    e.preventDefault();

    saveImage(formData, customer.id, (response)=>{
      if (response != undefined || response != null) {
        if (response.status === 201) {
          success(response.data.location);
        } else {
          failure("Invalid JSON: " + response);
        }
      }
    })
  }
  return (
    <div>
      <form onSubmit={handlerSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div className="card mb-3">
              <div className="card-header">
                <h1 className=" h4 font-weight-bold mb-1">
                  <i className="fas fa-palette mr-1"></i>
                  <span>Branding</span>
                </h1>
                <p className="mb-0 small font-weight-bold">
                  <span>Configura color y logo asociados a tu imagen corporativa</span>
                </p>
              </div>
              <div className="card-body">
                <p className="font-weight-bold mt-2">
                  <span>Selecciona el color</span>
                </p>
                <SelectColor onChange={handlerColor} handlerColor={handlerColor}/>
                <hr />
                <p className="font-weight-bold mt-2 mb-0">
                  <span>Establece tu Logo</span>
                </p>
                <p className="text-muted mt-0 mb-0 small">
                  <i className="fas fa-info-circle mr-2" />
                  <span>Logo: Dimensiones 250x250px</span>
                </p>
                <p className="text-muted small mb-2">
                  <i className="fas fa-info-circle mr-2" />
                  <span>Formatos permitidos: png, jpg, jpeg</span>
                </p>
                <input className="inputfile input-primary" type="file" accept= "image/*" name="user[logo]" id="logo" onChange={(e) => handleImageChange(e.target)}/>
                  <label className="text-center fileLabel" style={{ width: "100%" }}  htmlFor="logo">
                  <i className="fas fa-upload mr-2" />
                  <span>Cambiar Logo</span>
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-8 d-flex justify-content-center">
            <div className="col-md-10 col-12">
              <div className="material-shadow email-brand-preview-card profile-card mx-auto card">
                <div className="card-body">
                  <div className="image-cropper d-flex justify-content-center">
                    <img
                      src={ previewLogo }
                      alt="logo"
                      className="profile-pic"
                    />
                  </div>
                </div>
                <div className="card-body">
                  <div className="container">
                    <h5 className="text-left font-weight-bold mt-2">
                      Estimado(a)
                    </h5>
                    <p className="mt-3">
                      Esta previsualización corresponde al formato de los
                      correos que saldrán desde la plataforma{" "}
                      <span className="font-weight-bold" style={{ color: brandColor }} >
                        ControlDoc
                      </span>.
                    </p>
                    <p className="mt-3">
                      Puedes cambiar logo y colores de textos destacados.
                    </p>
                    <p className="mb-3">
                      Recuerda que al finalizar los cambios debes presionar el botón{" "}
                      <span className="font-weight-bold" style={{color: brandColor}}>
                        Guardar
                      </span>.
                    </p>
                    <p className="mb-3 font-weight-bold text-center" style={{ color: brandColor }}>
                      Recibe un Cordial Saludo
                      <br/>
                      Equipo ControlDoc
                    </p>
                  </div>
                </div>
                <div
                  className="pt-3 pb-3"
                  style={{
                    color: "white",
                    backgroundColor: brandColor
                  }}
                >
                  <p className="text-center mb-0"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    Este mensaje ha sido generado por un sistema de correos
                    automáticos.
                    <br />
                    No debe responderlo.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 p-0 m-0 d-flex justify-content-start">
            <div className="col-md-4 m-0 p-0 d-flex justify-content-center">
              <button className="btn btn-success mt-2" type="submit">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
export default BrandConfigurationForm;
