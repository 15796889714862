import React, { useRef, useState, useEffect } from "react";
import { Button, FormGroup, Label, Input } from "reactstrap";

import TemplateLayoutEditable from "./editable";
import I18n from "i18n-js";

import FieldError from "../helper/FieldError";

import { inputId, inputName } from "../helper/form";

import { create, update, preview } from "./axios";

import TemplateLayoutPreview from "./preview";

const TemplateLayoutForm = props => {
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const bodyExample = ' \
  <body> \
    <h1 class="text-center">Contrato</h1> \
    <p style="font-size: 20px; margin-bottom: 50px">Este texto corresponde a un ejemplo de un documento con la finalidad de poder previsualizar la configuración de la cabecera y el pie de página del documento. Es importante que te asegures que tu configuración se ve de forma correcta con el botón "Previsualizar" con el fin de evitar errores en los documentos.</p> \
  </body> \
  '

  const formName = "template_layout";
  const [templateLayout, setTemplateLayout] = useState(props?.templateLayout || {});
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewPdfUrl, setPreviewPdfUrl] = useState(null);

  const defaultRequestParams = {};

  const handleData = (e, key) => {
    let _template_layout = { ... templateLayout };

    let value = e?.target?.value;

    _template_layout[key] = value;
    setTemplateLayout(_template_layout);
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, templateLayout?.id || '')
    formData.append(`${ formName }[name]`, templateLayout?.name || '')
    formData.append(`${ formName }[header]`, headerRef?.current?.getContent() || '')
    formData.append(`${ formName }[footer]`, footerRef?.current?.getContent() || '')

    return formData;
  }

  const handlePreview = () => {
    let formData = getFormData();

    preview(defaultRequestParams, formData, response => {
      if(response.status == 200){
        let blob = new Blob([response.data], {type: "application/pdf"});
        let pdfUrl = URL.createObjectURL(blob);
        setPreviewPdfUrl(pdfUrl);
        setShowPreviewModal(true)
      } else {
        console.log("invalid JSON: " + response);
      }
    })
  }

  const handleConfirmSubmit = () => {
    swalWithBootstrap.fire({
      title: I18n.t('template_layouts.form.confirm.title'),
      html: I18n.t('template_layouts.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        handleSave();
      }
    })
  }

  const handleSave = () => {
    let formData = getFormData();

    let requestParams = {};

    if(templateLayout.id){
      requestParams = _.merge(requestParams, { id: templateLayout.id });
      update(requestParams, formData, response => {
        if(response.status == 200){
          handleSubmitRedirect(response);
        } else {
          setTemplateLayout(response.data);
        }
      })
    } else {
      create(requestParams, formData, response => {
        if(response.status == 201){
          handleSubmitRedirect(response);
        } else if(response.status == 422) {
          setTemplateLayout(response.data);
        }
      })
    }
  }

  const handleSubmitRedirect = response => {
    let requestParams = defaultRequestParams;
    if(response.status == 201 || response.status == 200){
      requestParams = { ... requestParams,
        id: response?.data?.hashid,
        _options: true,
        format: ''
      }

      window.location = Routes.template_layout_path(requestParams);
    }
  }

  return(
    <div className="row">
      <div className="col-12">
        <TemplateLayoutPreview
          showPreviewModal={ showPreviewModal }
          setShowPreviewModal={ setShowPreviewModal }
          previewPdfUrl={ previewPdfUrl }
        />

        <div className="card">
          <div className="card-body">
            <FormGroup>
              <FieldError errors={ templateLayout?.errors?.name || [] }>
                <Label
                  for={ inputId(formName, 'name') }
                >
                  { I18n.t(`activerecord.attributes.template_layout.name`) }
                </Label>

                <Input
                  id={ inputId(formName, 'name') }
                  name={ inputName(formName, 'name') }
                  invalid={ _.has(templateLayout?.errors, "name") }
                  onChange={ e => handleData(e, 'name') }
                  value={ templateLayout?.name || '' }
                />
              </FieldError>
            </FormGroup>
            <TemplateLayoutEditable
              editorRef={ headerRef }
              context={ 'header' }
              templateLayout={ templateLayout }
            />
            <div
              className="text-muted"
              dangerouslySetInnerHTML={{__html: bodyExample}}
            />
            <TemplateLayoutEditable
              editorRef={ footerRef }
              context={ 'footer' }
              templateLayout={ templateLayout }
            />
          </div>
        </div>
      </div>
      <FormGroup className="text-end">
        <a
          href={ Routes.template_layouts_path() }
          className='btn btn-default'
        >
          { I18n.t('actions.back') }
        </a>
        <Button
          color='primary'
          className='ml-2'
          onClick={ e => handlePreview() }
        >
          { I18n.t('actions.preview') }
        </Button>
        <Button
          color='success'
          className='ml-2'
          onClick={ e => handleConfirmSubmit() }
        >
          { I18n.t('actions.save') }
        </Button>
      </FormGroup>
    </div>
  )
}

export default TemplateLayoutForm;
