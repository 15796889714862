export const inputId = (formName, inputName) => {
  let _formName = formName.replace(/[\[\]']+/g, '_');
  let _inputName = inputName.replace(/[\[\]']+/g, '_');

  if(_formName[_formName.length - 1] != '_'){
    _formName = _formName + '_';
  }

  return `${_formName}${_inputName}`
}

export const inputName = (formName, inputName) => {
  return `${formName}[${inputName}]`
}

export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
